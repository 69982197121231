import React from "react";

export const data_copywriting = {
  what_we_do: {
    title: "Co wyróżnia nasze teksty?",
    text: [
      <>
        <b>Kreatywność to nasze drugie imię.</b> Chcemy Cię wyróżnić wśród
        konkurencji, dlatego nasze teksty przygotowujemy nieszablonowo i
        oryginalnie. Mamy głowy pełne pomysłów, co chętnie wykorzystujemy w
        naszej pracy.
      </>,
      <>
        <b>Słownik ortograficzny znamy na pamięć.</b> Piszemy jasno i
        zrozumiale, z zachowaniem poprawności językowej. Wychwytujemy literówki
        i poprawiamy błędy. Komunikaty, które tworzymy, są profesjonalne i
        dopracowane w każdym calu.
      </>,
      <>
        <b>Skopiowane teksty? Nigdy w życiu.</b> Wolimy spędzić cały dzień na
        szukaniu inspiracji i researchu, niż przepisać treść z innej strony.
        Nasze teksty cechuje unikalność. Szanujemy własność intelektualną -
        niepowtarzalny i jedyny w swoim rodzaju content to podstawa.
      </>,
      <>
        <b>Atrakcyjnie zredagowane teksty zawierają frazy kluczowe.</b> Na
        pierwszym miejscu jesteś Ty, ale nie zapominamy również o SEO
        copywritingu. Piszemy treści, które są zoptymalizowane pod kątem
        wyszukiwarek internetowych.
      </>,
    ],
  },
  our_process: {
    title: "Nasz proces projektowy",
    steps: [
      {
        title: "Planowanie",
        list: [
          "Brief",
          "Określenie celu",
          "Określenie grupy odbiorców",
          "Zebranie i analiza danych",
        ],
      },
      {
        title: "Przygotowanie",
        list: [
          "Opracowanie koncepcji",
          "Zebranie informacji / materiałów",
          "Napisanie tekstu",
          "Optymalizacja tekstu pod kątem SEO",
        ],
      },
      {
        title: "Obsługa",
        list: [
          "Redakcja i poprawki",
          "Korekta tekstu",
          "Testowanie efektywności",
        ],
      },
    ],
  },
};
